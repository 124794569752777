class LatestNews {

	constructor(_latestnewsContainer) {
		// Variables
		this.container = _latestnewsContainer;
		this.loadmoreParams = {};
		this.init();
	}

	// Functions
	init() {
		const _this = this;

		this.loadmoreParams = this.container.data('loadmore');

		// create  navi
		this.createNavi();

		this.container.find('.load-more').click(function (evt) {
			evt.preventDefault();
			_this.loadmore();
		});
	};

	createNavi() {
		var _this = this;

		let navi = '<ul>';

		for (let p = 1; p <= this.loadmoreParams.max_num_pages; p++) {
			if (p>5)navi += '<li class="hide"><button>' + p + '</button></li>';
			else if (p===1)navi += '<li class="active"><button>' + p + '</button></li>';
			else navi += '<li><button>' + p + '</button></li>';
		}

		navi += '</ul>';
		this.container.find('.pagination').append(navi);

		this.container.find('.pagination button').on('click', function(evt) {
			_this.jumpToPage( $(this).parent().index() + 1 );
		});
	};

	updateNavi() {
		let naviEntries = this.container.find('.pagination li');
		naviEntries.addClass('hide').removeClass('active');

		let pCount = 0;

		for (let p = this.loadmoreParams.paged - 3; p <= this.loadmoreParams.max_num_pages; p++) {
			if ( p < 0 ) {
				continue;
			}
			
			if ( pCount == 5 ) {
				break;
			}

			naviEntries.eq(p).removeClass('hide');
			if (p === this.loadmoreParams.paged-1) {
				naviEntries.eq(p).addClass('active')
			}
			pCount ++;
		};

	};

	jumpToPage( _pageIndex ) {
		this.loadmoreParams.paged = _pageIndex;
		this.loadmore();
	};

	loadmore() {
		var _this = this;

		if ( _this.loadmoreParams.layout === 'featured-list' ) {
			_this.loadmoreParams.paged++;
		}

		var data = {
			action: 'loadlatestposts',
			nonce: latestnewsAjax.nonce,
			layout: _this.loadmoreParams.layout,
			query: {
				'posts_per_page': _this.loadmoreParams.posts_per_page,
				'tag__in': _this.loadmoreParams.tag__in,
				'paged': _this.loadmoreParams.paged,
				'offset': _this.loadmoreParams.offset + _this.loadmoreParams.paged * _this.loadmoreParams.page,
				'order': _this.loadmoreParams.order,
				'orderby': _this.loadmoreParams.orderby,
				'category__in': _this.loadmoreParams.category__in,
				'display_post_date': _this.loadmoreParams.display_post_date,
				'cols': _this.loadmoreParams.cols,
				'rows': _this.loadmoreParams.rows,
			},
		};

		$.ajax({ // you can also use $.post here
			url: latestnewsAjax.ajaxurl, // AJAX handler
			data: data,
			type: 'POST',
			success: function (res) {
				console.log(res);
				var response = JSON.parse(res);
				if (response) {					

					if ( _this.loadmoreParams.layout === 'featured-list' ) {
						_this.container.find('.load-more').before(response.data);
						_this.container.find('.article-list').addClass('scrollable');
					} else {
						_this.container.find('.article-list .news-post').remove();
						_this.container.find('.article-list .pagination').before(response.data);
						_this.updateNavi();
					}
				} else {
					if ( _this.loadmoreParams.layout === 'featured-list' ) {
						_this.container.find('.load-more').remove(); // if no data, remove the button as well
					}
				}
			}
		});
	};
}

$(document).ready(function () {
	$('.wp-block-boldblock-latestnews').each(function (e, i) {
		new LatestNews($(this))
	})
});